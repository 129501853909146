import { Input_, Builder_, Confirmation_, Dropdown_, Elastic_Search, Main_Plain, Notes_, Red_, Textarea_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getUserInfoOfAssignedSeller } from '../utils'

const LeadsEdit = styled.div`

`


const LeadsEditTextarea = styled.div`
	position: relative;

	textarea{
		padding-bottom: 4rem;
	}
	
	button{
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
`

const LeadsEditNotes = styled.div`

	ul{
		/* min-height: 30rem;
		height: calc(100vh - 53rem);
		overflow: auto; */
	}
`

const LeadActions = styled.div`
    display: flex;
    justify-content: right;
`
export const Leads_Edit = ({selectedLead, setSelectedLead, sellers, hasPermission, handleAddNote, user, setUser, setCustomTag, note, setNote, handleDeleteLead}) => {

    const {t} = useTranslation();
	const [noteToEdit, setNoteToEdit] = useState(null);
    const [leadToDelete, setLeadToDelete] = useState(false)
    const [noteToDelete, setNoteToDelete] = useState(false)

	const handleEditNote = (content, i) => {

		const updatedNotes = selectedLead.notes.map((note, index) =>
			index === i ? { ...note, note: content } : note
		);
	
		setSelectedLead(prevLead => ({
			...prevLead,
			notes: updatedNotes
		}));
	};


    const handleDeleteNote = () => {
		const updatedNotes = selectedLead.notes.filter((note, index) => index !== noteToDelete);
	
		setSelectedLead(prevLead => ({
			...prevLead,
			notes: updatedNotes
		}));

        setNoteToDelete(false)
	}

    const handleDelete = () => {
        handleDeleteLead(leadToDelete)
        setLeadToDelete(false)
    }

    console.log('toDelete', noteToDelete, leadToDelete);
    
    
    console.log('selectedLead!!', selectedLead);
    console.log('sellers', sellers);


    useEffect(() => {
       const tost=getUserInfoOfAssignedSeller(selectedLead);
       console.log('tost', tost);
       
    }, []);
    console.log('user!!', user);

    const assignedSeller = getUserInfoOfAssignedSeller(selectedLead)
    
  return (
    <LeadsEdit>
        {leadToDelete !== false  || noteToDelete !== false ? 
            <Confirmation_
                message={leadToDelete !== false ? t("Are you sure you want to delete this lead?") : noteToDelete!==false ? t("Are you sure you want to delete this note?") : null }
                no={t("No")}
                onNo={leadToDelete !== false ? () => setLeadToDelete(false) : noteToDelete!==false ? ()=>setNoteToDelete(false) : '' }
                onYes= {leadToDelete !== false ? ()=> handleDelete() : noteToDelete!==false ? ()=>handleDeleteNote() : '' }
                yes={t("Yes")}
        /> : null }
        {/* <LeadActions>
            <Red_ noPadding text={t('Delete lead')} iconLeft="Delete" onClick={() => setLeadToDelete(selectedLead)} />
        </LeadActions> */}
        <Builder_ title={t("Enter lead's first name")}>
            <Input_
                placeholder={t("First name")}
                value={selectedLead?.firstname}
                onChange={(e) => setSelectedLead({ ...selectedLead, firstname: e.target.value, edit: true })}
            />
        </Builder_>
        <Builder_ title={t("Enter lead's last name")}>
            <Input_
                placeholder={t("Last name")}
                value={selectedLead?.lastname}
                onChange={(e) => setSelectedLead({ ...selectedLead, lastname: e.target.value, edit: true })}
            />
        </Builder_>
        {/* <Builder_ title={t("Enter lead's email address")}>
            <Input_
                placeholder={t("Email address")}
                value={selectedLead?.email}
                onChange={(e) => setSelectedLead({ ...selectedLead, email: e.target.value, edit: true })}
            />
        </Builder_> */}
        <Builder_ title={t("Enter lead's phone number")}>
            <Input_
                placeholder={t("Phone number")}
                value={selectedLead?.phone}
                onChange={(e) => setSelectedLead({ ...selectedLead, phone: e.target.value, edit: true })}
            />
        </Builder_>			
        <Builder_ title="Assign Seller">
            {/* <Elastic_Search
                debounce={750}
                defaultSelected={{username:selectedLead?.sellerId?.username}}
                displayProperty="username"
                file={sellers}
                maximumSuggestions={7}
                placeholder="Search"
                results={(data) => setUser(data)}
                returnType="object"
                searchKeys={[
                    'username'
                ]}
                selectionType="one"
            /> */}
            <Dropdown_>
                <select 
                    value={user?.id || 'none'} 
                    onChange={(e) => {
                        const selectedSeller = sellers.find(seller => seller.id === e.target.value);
                        if (selectedSeller) {
                            setUser(selectedSeller);
                        }
                    }}
                >
                    <option value={'none'} disabled>{t('Seller')}</option>
                    {sellers?.map((seller) => (
                        <option
                            key={seller.id}
                            value={seller.id}
                        >
                            {seller.username}
                        </option>
                    ))}
                </select>
            </Dropdown_>
        </Builder_>
        <Builder_ title="Custom Tag">
            <Dropdown_>
                <select
                    defaultValue={selectedLead?.tags?.find(item => item.type === 'custom' && item.status)?.name}
                    onChange={(e) => setSelectedLead({...selectedLead, customTag:e.target.value})}
                >
                    <option value="">Select a tag</option>
                    <option>Answered</option>
                    <option>Not Answered</option>
                    <option>Meeting Set</option>
                    <option>Customer Won</option>
                    <option>Customer Lost</option>
                </select>
            </Dropdown_>
        </Builder_>
        <Builder_ title={t("Merge with another lead")}>
            <Input_
                placeholder={t("Email address of lead")}
                value={selectedLead?.mergeLead}
                onChange={(e) => setSelectedLead({ ...selectedLead, mergeLead: e.target.value, edit: true })}
            />
        </Builder_>
        <LeadsEditNotes>
            <Notes_
                notes={selectedLead?.notes}
                user='adminId'
                text='note'
                date='createdAt'
                // actions={false}
                // delete={hasPermission}
                // onDelete={(note,i)=> setNoteToDelete(i)}
                // onEdit={(note, i) => {
                //     setNoteToEdit({note: note.note, index: i });
                // }}
                // edit = {
                //     <Builder_>
                //         <Textarea_
                //             defaultValue={noteToEdit?.note}
                //             onChange={(e) => handleEditNote(e.target.value, noteToEdit?.index)}
                //             placeholder="Edit your note..."
                //         />
                //     </Builder_>
                // }
                input={
                    <Builder_ title="Add Notes">
                        <LeadsEditTextarea>
                            <Textarea_
                                value={selectedLead?.note}
                                onChange={(e) => setSelectedLead({...selectedLead, note:e.target.value})}
                                placeholder="Add your note..."
                            />
                            <Main_Plain noPadding iconLeft="Add" text="Add note"  onClick={handleAddNote} />
                        </LeadsEditTextarea>
                    </Builder_>
                }
            />
		</LeadsEditNotes>
    </LeadsEdit>
  )
}
