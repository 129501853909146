import { Builder_, Dropdown_, Elastic_Search, Input_, Textarea_ } from 'monica-alexandria';
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const LeadsAdd = styled.div``

export default function Leads_Add(props) {

  const {t} = useTranslation();
	console.log('props.user', props.user);
	
  return (
    <LeadsAdd>
        <Builder_ title={t("Enter lead's first name")}>
						<Input_
							placeholder={t("First name")}
							value={props.valueFirstName}
							onChange={props.onFirstName}
						/>
					</Builder_>
					<Builder_ title={t("Enter lead's last name")}>
						<Input_
							placeholder={t("Last name")}
							value={props.valueLastName}
							onChange={props.onLastName}
						/>
					</Builder_>
					<Builder_ title={t("Enter lead's email address")}>
						<Input_
							placeholder={t("Email address")}
							value={props.valueEmail}
							onChange={props.onEmail}
						/>
					</Builder_>
					<Builder_ title={t("Enter lead's phone number")}>
						<Input_
							placeholder={t("Phone number")}
							value={props.valuePhone}
							onChange={props.onPhone}
						/>
					</Builder_>
					<Builder_ title={t("Assign seller")}>
			
					<Dropdown_>
						<select 
							value={props.user?.id || 'none'} 
							onChange={(e) => {
								const selectedSeller = props.fileAssignee?.find(seller => seller.id === e.target.value);
								console.log('selectedSeller', selectedSeller);
								
								if (selectedSeller) {
									props.resultsAssignee(selectedSeller);
								}
							}}
						>
							<option value={'none'} disabled>{t('Seller')}</option>
							{props.fileAssignee?.map((seller) => (
								<option
									key={seller.id}
									value={seller.id}
								>
									{seller.username}
								</option>
							))}
						</select>
					</Dropdown_>
					
					</Builder_>
					<Builder_ title={t("Select lead's status")}>
						<Dropdown_>
							<select
								value={props.valueStatus}
								onChange={props.onStatus}
							>
								<option value=''>{t("Open")}</option>
								<option>{t("Answered")}</option>
								<option>{t("Not Answered")}</option>
								<option>{t("Meeting Set")}</option>
								<option>{t("Customer Won")}</option>
								<option>{t("Customer Lost")}</option>
							</select>
						</Dropdown_>
					</Builder_>
					<Builder_ title={t("Notes")}>
						<Textarea_
							value={props.valueNotes}
							onChange={props.onNotes}
							placeholder={t("Type your notes here...")}
						/>
					</Builder_>
    </LeadsAdd>
  )
}
